import type { RouterConfig } from '@nuxt/schema';
import { filterRoutesByExcludes } from '~/helpers/common';

const excludes = ['home', 'components'];

// https://router.vuejs.org/api/interfaces/routeroptions.html#routes
export default <RouterConfig>{
  routes: _routes => [
    ...filterRoutesByExcludes(_routes, excludes),
    {
      name: 'home',
      path: '/',
      component: () => import('~/pages/home/Index.vue').then(r => r.default || r),
    },
    { path: '/terms', redirect: 'policy-user' },
    { path: '/agreement-18', redirect: 'policy-agreement' },
    { path: '/copyright_docs', redirect: 'policy-copyright' },
    { path: '/right-holder-policy', redirect: 'policy-holder' },
    { path: '/tariffs', redirect: 'subscriptions' },
  ],
};
