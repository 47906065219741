export default defineNuxtRouteMiddleware((to, from) => {
  const app = useNuxtApp();
  const { user } = storeToRefs(useAuthStore(app.$pinia));
  const targets = ['profile', 'favorites'];
  const { onReveal } = useAuth();

  if (targets.some(target => String(to.name).includes(target)) && !user.value) {
    if (import.meta.client) onReveal();
    if (!from.name) return navigateTo({ name: 'home' });
    else return navigateTo({ name: from.name });
  } else {
    return;
  }
});
