export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  window._tmr = window._tmr || [];
  window._tmr.push({
    id: config.public.mailRuId,
    type: "pageView",
    start: (new Date()).getTime(),
    pid: "USER_ID"
  });

  useHead({
    script: [
      {
        src: 'https://top-fwz1.mail.ru/js/code.js',
        defer: true,
        async: true,
        tagPosition: 'head',
        onload: () => {
          // Добавление noscript элемента для случаев, когда JavaScript отключен
          const noscript = document.createElement('noscript');
          const div = document.createElement('div');
          const img = document.createElement('img');

          img.src = `https://top-fwz1.mail.ru/counter?id=${config.public.mailRuId};js=na`;
          img.style.border = '0';
          img.style.position = 'absolute';
          img.style.left = '-9999px';
          img.alt = 'Top.Mail.Ru';

          div.appendChild(img);
          noscript.appendChild(div);
          document.body.appendChild(noscript);
        }
      },
    ],
  });
});
