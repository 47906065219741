import { string, addMethod } from 'yup';
import { defineNuxtPlugin } from '#imports';

// const emailRegex = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const emailRegex = /^[\s]*([a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+[\s]*)+\@([\s]*[a-zA-Z0-9\-]+[\s]*\.)+[\s]*([a-zA-Z0-9]{2,10})[\s]*$/;
// const phoneRegex = /^(?:\+7|8)\s?\d{3}\s?\d{3}\s?\d{2}\s?\d{2}$/;
const phoneRegex = /^(?:\+7|7|8)?[\s\-]?\(?9\d{2}\)?[\s\-]?\d{3}[\s\-]?\d{2}[\s\-]?\d{2}$/;
const numeric = /^\d+$/;

export default defineNuxtPlugin(nuxt => {
  addMethod(string, 'email', function validateEmail(message) {
    return this.matches(emailRegex, {
      message,
      name: 'email',
      excludeEmptyString: true,
    });
  });

  addMethod(string, 'phone', function validatePhone(message) {
    return this.matches(phoneRegex, {
      message,
      name: 'phone',
      excludeEmptyString: true,
    });
  });

  addMethod(string, 'numeric', function validateNumeric(message) {
    return this.matches(numeric, {
      message,
      name: 'numeric',
      excludeEmptyString: true,
    });
  });
});
