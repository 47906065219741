import { type Component, type ComputedRef } from 'vue';
import type { ComponentProps, LayerID } from '@/types/modal';
import type { EventHookOn } from '@vueuse/core';

type PropsBehaviorOptions = {
  chore: boolean;
  keepInitial: boolean;
  id?: LayerID | null;
};

export function createLayer<C extends Component>(
  component: C,
  initialAttrs: ComponentProps<C> = {} as ComponentProps<C>,
  options: PropsBehaviorOptions = { chore: false, keepInitial: false, id: null },
): {
  closeAll: () => void;
  close?: (data?: any) => void;
  reveal?: (props?: ComponentProps<C>) => Promise<any>;
  isRevealed?: ComputedRef<boolean>;
  onClose: EventHookOn | any;
  onReveal: EventHookOn | any;
} {
  const setAttrs = (key: string | symbol | number, attrs: ComponentProps<C> | null) => {
    if (!propsRef.value[key]) {
      propsRef.value[key] = {};
    }

    if (!attrs) {
      propsRef.value[key] = {};
      return;
    }

    for (const prop in attrs) {
      propsRef.value[key][prop] = attrs[prop];
    }
  };

  const propsRef = ref<any>({} as ComponentProps<C>);
  const { events = {}, ...restAttrs } = initialAttrs;
  // @ts-ignore
  setAttrs(0, restAttrs);
  const revealed = ref(false);

  const { removeAll, removeLayer, getNewLayerID, LayerStore, addLayer } = useLayerConductor();

  let LAYER_IDs: Array<string | number | symbol> = [];

  const onReveal = async (props?: ComponentProps<C>, key?: string | number) => {
    revealed.value = true;

    if (!LAYER_IDs.length) LAYER_IDs = [options.id ?? getNewLayerID()];
    const currentLayerID = key ?? LAYER_IDs[LAYER_IDs.length - 1];

    if (props) setAttrs(currentLayerID, props as ComponentProps<C>);
    if (key) LAYER_IDs.push(key);

    await addLayer({
      id: currentLayerID,
      component: toRaw(component),
      props: propsRef.value[currentLayerID],
      isModal: true,
      revealed,
      events: {
        close: onClose,
        ...events,
      },
    });
  };

  const onClose = async () => {
    const currentLayerID = LAYER_IDs.pop(); // Удаляем последний ключ из массива
    if (currentLayerID) {
      await removeLayer(currentLayerID);
    }
  };

  const closeAll = () => {
    LayerStore.forEach(layer => {
      layer.revealed.value = false;
    });
    removeAll();
  };

  return {
    // close,
    isRevealed: computed(() => revealed.value),
    closeAll,
    onClose,
    onReveal,
  };
}

export const createModal = createLayer;
