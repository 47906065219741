import { useMutation } from '@tanstack/vue-query';
import type { IQueryParams } from '~/services/types';
import { useNuxtApp } from '#app';
import { type SearchParameters } from 'ofetch';

export function useIMutation<T>(url: string, options?: IQueryParams) {
  return useMutation({
    // mutationKey: [options?.key ?? url, options?.params],
    mutationFn: (body?: SearchParameters) => useNuxtApp().$apiFetch<T>(url, { body, method: options?.method }),
    ...options?.options,
  });
}
