<script lang="ts" setup>
  import VSocialItems from '~/components/footer/VSocialItems.vue';

  const stores = [
    {
      title: 'Для смартфона',
      items: [
        {
          to: '/install/ios',
          icon: 'ios',
          title: 'iOS',
        },
        {
          to: '/install/android',
          icon: 'android',
          title: 'Android',
        },
        // {
        //   to: '/install/pwa',
        //   icon: 'pwa',
        //   title: 'Установка с сайта',
        // },
      ],
    },
    {
      title: 'Для ТВ',
      items: [
        {
          to: '/install/apple-tv',
          icon: 'ios',
          title: 'Apple TV',
        },
        {
          to: '/install/android-tv',
          icon: 'android',
          title: 'Android TV',
        },
        {
          to: '/install/samsung',
          icon: 'samsung',
          title: 'Samsung',
        },
        {
          to: '/install/yandex',
          icon: 'yandex',
          title: 'Яндекс ТВ',
        },
        {
          to: '/install/lg',
          icon: 'lg',
          title: 'LG',
        },
      ],
    },
  ];
</script>

<template>
  <div class="v-footer-stores">
    <div class="v-footer-stores__wrapper" v-for="store in stores" :key="store.title">
      <p class="v-footer-stores__title">{{ store.title }}</p>
      <div class="v-footer-stores__items">
        <VSocialItems v-for="link in store.items" :key="link.to" :item="link" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .v-footer-stores {
    display: grid;
    gap: 24px;

    &__wrapper {
      display: grid;
      gap: 8px;
    }

    &__title {
      color: $main_white;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    &__items {
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
      row-gap: 12px;

      img {
        height: 32px;
      }
    }

    @media (max-width: $retina) {
      &__items {
        justify-content: flex-start;
      }
    }
  }
</style>
