import useStatefulCookie from '~/composables/useStatefulCookies';
import { clearQueries, objectToQueryString } from '~/helpers/url';
import { callWithNuxt } from '#app';
import type { CookieRef } from '#app';
import type { ICookieCredits } from '~/types/common';

export const useRedirect = (nuxtApp?: any) => {
  const config = useRuntimeConfig();
  const credits: CookieRef<ICookieCredits | null> = useStatefulCookie('credits');
  const isTargetKgDomain = useState('isTargetKgDomain');
  const { $pwa } = useNuxtApp();

  const checkUrlCredits = () => {
    const queryNames = ['code', 'code2'];
    const { searchParams } = useRequestURL();
    const [access, refresh] = [searchParams.get('code'), searchParams.get('code2')];
    clearQueries(queryNames);

    return { access, refresh };
  };

  const redirectToPersonalDomain = (params: { [key: string]: any } = {}) => {
    if (!credits.value?.access) return;
    if (config.public.redirectDisabled || $pwa?.isPWAInstalled) return;

    const { access, refresh } = credits.value;
    const { pathname, search } = useRequestURL();

    const payload = {
      token: access,
      refresh: refresh,
      append: `?to=${pathname}`,
      external: objectToQueryString(params).slice(1),
    };

    const searchParams = objectToQueryString(payload);

    if (config.public.isLanding) {
      console.log('redirect: personal domain');

      if (nuxtApp) {
        return callWithNuxt(nuxtApp, navigateTo, [`/loading/${searchParams}`, { external: true }]);
      } else {
        return navigateTo(`/loading/${searchParams}`, { external: true });
      }
    }
  };

  const redirectToLanding = () => {
    if (config.public.redirectDisabled || config.public.isLanding || $pwa?.isPWAInstalled) return;
    console.log('redirect: landing');

    const payload: any = {};
    if (isTargetKgDomain.value) payload.country = 'KG';

    const params = objectToQueryString(payload);

    if (nuxtApp) {
      return callWithNuxt(nuxtApp, navigateTo, [`/loading/${params}`, { external: true }]);
    } else {
      return navigateTo(`/loading/${params}`, { external: true });
    }

    // return callWithNuxt(nuxtApp, navigateTo, [`/loading/${params}`, { replace: true, redirectCode: 301 }]);
  };

  return {
    checkUrlCredits,
    redirectToPersonalDomain,
    redirectToLanding,
  };
};
