import { hasTargetDomains } from '~/helpers/url';

export default defineNuxtPlugin(async (nuxtApp: any) => {
  const { host } = useRequestURL();

  const targetDomains = ['kg', 'kz'];
  const isTargetKgDomain = hasTargetDomains(host, targetDomains);

  useState('isTargetKgDomain', () => isTargetKgDomain);
});
