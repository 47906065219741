import type { CookieOptions, CookieRef } from '#app';
import { refDebounced } from '@vueuse/core';

export default function useStatefulCookie<T = string>(
  name: string,
  options?: Omit<CookieOptions, 'expires' | 'readonly'>,
  expires: number = 365,
): CookieRef<T> {
  const expiresDate = new Date();
  expiresDate.setTime(expiresDate.getTime() + expires * 24 * 60 * 60 * 1000);

  const cookie = useCookie<T>(name, { expires: expiresDate, sameSite: 'lax', ...options });
  const state = useState<T>(name, () => cookie.value);

  watch(
    state,
    () => {
      cookie.value = state.value;
    },
    { deep: true },
  );

  return state;
}
