import revive_payload_client_Frd8gASYWa from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "/srv/gitlab-runner/films/landing_redesign/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/.nuxt/pwa-icons-plugin.ts";
import pwa_client_GeMvvjCziF from "/srv/gitlab-runner/films/landing_redesign/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import nuxt_plugin_1kGPU1Pr7M from "/srv/gitlab-runner/films/landing_redesign/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import _00_global_state_Zfk9MY99fr from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/00.global.state.ts";
import _01_fetch_UmBiKFhIzW from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/01.fetch.ts";
import _02_vue_query_DoUlB9VOLz from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/02.vue-query.ts";
import jivo_client_Sw2XVN9cQU from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/jivo.client.ts";
import mail_ru_counter_client_otEUZK2Y2o from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/mail-ru-counter.client.ts";
import referral_query_parser_yA7NHdzcXg from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/referral-query-parser.ts";
import sentry_client_config_cPKJD40CT4 from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/sentry.client.config.ts";
import vk_retargeting_client_3stWKe4BdR from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/vk-retargeting.client.ts";
import yandex_metrics_client_pGfuBZddGp from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/yandex-metrics.client.ts";
import yup_Rz6YTAoXxh from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/plugins/yup.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_GeMvvjCziF,
  nuxt_plugin_1kGPU1Pr7M,
  _00_global_state_Zfk9MY99fr,
  _01_fetch_UmBiKFhIzW,
  _02_vue_query_DoUlB9VOLz,
  jivo_client_Sw2XVN9cQU,
  mail_ru_counter_client_otEUZK2Y2o,
  referral_query_parser_yA7NHdzcXg,
  sentry_client_config_cPKJD40CT4,
  vk_retargeting_client_3stWKe4BdR,
  yandex_metrics_client_pGfuBZddGp,
  yup_Rz6YTAoXxh
]