<script setup lang="ts">
  import type { RouteLocationRaw } from 'vue-router';

  interface IProps {
    type?: string;
    to?: RouteLocationRaw;
    icon?: string;
    size?: 'large' | 'normal' | 'small';
    appearance?: 'fill' | 'outline' | 'text';
    color?: 'main' | 'secondary' | 'white' | 'gray' | 'transparent-08';
    media?: 'large' | 'normal' | 'small';
    link?: boolean;
    rounded?: boolean;
    loading?: boolean;
    disabled?: boolean | number;
    customTag?: string;
  }

  const props = withDefaults(defineProps<IProps>(), {
    type: 'button',
    size: 'large',
    appearance: 'fill',
    color: 'main',
    disabled: false,
  });
  const iconName = computed(() => `icon-${props.loading ? 'loading' : props.icon}`);
  const isDisabled = computed(() => (props.disabled ? 'disabled' : null) as string);
  const isType = computed(() => (props.type && !props.to ? 'type' : null) as string);
</script>

<template>
  <component
    :is="props.customTag ? props.customTag : props.to ? 'router-link' : 'button'"
    class="v-button"
    :class="[
      `v-button--color-${props.color}`,
      `v-button--media-${props.media}`,
      {
        'v-button--rounded': props.rounded,
        'v-button--loading': props.loading,
        'v-button--link': props.link,
      },
    ]"
    :data-appearance="props.appearance"
    :data-size="props.size"
    :[isType]="props.type"
    :to="props.to"
    :[isDisabled]="props.disabled"
  >
    <component :is="iconName" v-if="props.icon || props.loading" />
    <slot v-if="!props.rounded && !props.loading"></slot>
  </component>
</template>

<style lang="scss">
  $button-sizes: (
    'large': (
      'button-font-size': 18px,
      'button-padding': 16px 24px,
      'button-line-height': 28px,
      'rounded-padding': 18px,
      'svg-width': 24px,
      'svg-height': 24px,
    ),
    'normal': (
      'button-font-size': 16px,
      'button-padding': 12px 16px,
      'button-line-height': 24px,
      'rounded-padding': 12px,
      'svg-width': 24px,
      'svg-height': 24px,
    ),
    'small': (
      'button-font-size': 14px,
      'button-padding': 8px,
      'button-line-height': 20px,
      'svg-width': 16px,
      'svg-height': 16px,
      'rounded-padding': 10px,
    ),
  );
  .v-button {
    $parent: &;
    --button-color: #{$main_black};
    --button-background: #{$main_yellow_light};
    --button-hover: #{$main_yellow_mid};
    --button-active: #{$main_yellow_dark};
    --button-disabled: #{$secondary_grey_green_mid};
    --button-outline-hover: #{$main_grey_extra_dark};
    --button-outline-active: #{$secondary_grey_dark};

    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--button-padding);
    gap: 8px;
    border-radius: 32px;
    background: var(--button-background);
    color: var(--button-color);
    border: none;
    font-size: var(--button-font-size);
    font-style: normal;
    font-weight: 600;
    line-height: var(--button-line-height);
    transition: 0.3s;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;

    &:hover:not(&--loading):not(&[disabled]) {
      background-color: var(--button-hover);
    }

    &:active:not(&--loading):not(&[disabled]) {
      background-color: var(--button-active);
    }

    &[disabled] {
      background-color: var(--button-disabled);
      pointer-events: none;
      cursor: default;
    }

    svg {
      width: var(--svg-width);
      height: var(--svg-height);
    }

    &[data-appearance='outline'] {
      box-shadow: inset 0 0 0 1px var(--button-background);
      background-color: transparent;
      --button-color: var(--button-background);

      svg {
        transition: 0.3s;
        color: var(--button-background);
        fill: var(--button-background);
      }

      &:hover:not(#{$parent}--loading):not(&:disabled) {
        background-color: var(--button-outline-hover);
        --button-background: var(--button-hover);
        --button-color: var(--button-hover);
      }

      &:active:not(#{$parent}--loading):not(&:disabled) {
        background-color: var(--button-outline-active);
        --button-background: var(--button-active);
        --button-color: var(--button-active);
      }

      &[disabled] {
        background-color: transparent;
        --button-background: var(--button-disabled);
        --button-color: var(--button-disabled);
      }
    }

    &[data-appearance='text'] {
      background-color: transparent;
      --button-color: var(--button-background);

      svg {
        transition: 0.3s;
        color: var(--button-color);
        fill: var(--button-color);
      }

      &:hover:not(#{$parent}--loading):not(&:disabled) {
        background-color: transparent;
        --button-color: var(--button-hover);
      }

      &:active:not(#{$parent}--loading):not(&:disabled) {
        background-color: transparent;
        --button-background: var(--button-active);
        --button-color: var(--button-active);
      }

      &[disabled] {
        pointer-events: none;
        background-color: transparent;
        --button-background: var(--button-disabled);
        --button-color: var(--button-disabled);
      }
    }

    &--color-secondary {
      --button-background: #{$secondary_red_light};
      --button-color: #{$main_white};

      &:hover {
        --button-hover: #{$secondary_red_mid};
        --button-color: #{$main_grey_mid};
      }

      &:active {
        --button-active: #{$secondary_red_dark};
        --button-color: #{$main_grey_dark};
      }

      &[disabled] {
        --button-disabledr: #{$main_grey_dark};
      }
    }

    &--color-white {
      --button-background: #{$main_white};
      --button-color: #{$main_black};

      &:hover {
        --button-hover: #{$main_grey_mid};
        --button-color: #{$main_black};
      }

      &:active {
        --button-active: #{$main_grey_dark};
        --button-color: #{$main_black};
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};
      }
    }

    &--color-gray {
      --button-background: #{$secondary_grey_mid};
      --button-color: #{$main_white};

      &:hover {
        --button-hover: #{$secondary_grey_mid};
        --button-color: #{$main_grey_mid};
      }

      &:active {
        --button-active: #{$secondary_grey_dark};
        --button-color: #{$secondary_grey_light};
      }

      &[disabled] {
        --button-disabled: #{$main_grey_dark};
      }
    }

    &--color-transparent-08 {
      --button-background: #{$transparent_08};
      --button-color: #{$main_white};

      &:active {
        --button-color: #{$secondary_grey_light};
      }
    }

    @each $size, $properties in $button-sizes {
      &[data-size='#{$size}'] {
        @each $property, $value in $properties {
          --#{$property}: #{$value};
        }
      }
    }

    &--rounded {
      width: fit-content;

      &#{$parent} {
        @each $size, $properties in $button-sizes {
          &[data-size='#{$size}'] {
            --button-padding: #{map-get($properties, 'rounded-padding')};
          }
        }
      }
    }

    @media (max-width: $retina) {
      @each $media, $properties in $button-sizes {
        &--media-#{$media} {
          &#{$parent} {
            @each $size in (large, normal, small) {
              &[data-size='#{$size}'] {
                --button-font-size: #{map-get($properties, 'button-font-size')};
                --button-padding: #{map-get($properties, 'button-padding')};
                --button-line-height: #{map-get($properties, 'button-line-height')};
              }
            }

            &--rounded {
              @each $size in (large, normal, small) {
                &[data-size='#{$size}'] {
                  --button-padding: #{map-get($properties, 'rounded-padding')};
                }
              }
            }
          }
        }
      }
    }

    &--loading {
      pointer-events: none;
    }

    &--link {
      flex-direction: row-reverse;
    }
  }
</style>
