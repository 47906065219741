// @ts-nocheck
export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  useHead({
    script: [
      {
        src: 'https://vk.com/js/api/openapi.js?169',
        defer: true,
        async: true,
        tagPosition: 'head',
        onload: () => {
          VK.Retargeting.Init(config.public.vkTargetingId);
          VK.Retargeting.Hit();

          // Добавление noscript элемента для случаев, когда JavaScript отключен
          const noscript = document.createElement('noscript');
          const div = document.createElement('div');
          const img = document.createElement('img');

          img.src = `https://vk.com/rtrg?p=${config.public.vkTargetingId}`;
          img.style.position = 'absolute';
          img.style.left = '-9999px';
          img.alt = '';

          div.appendChild(img);
          noscript.appendChild(div);
          document.body.appendChild(noscript);
        }
      },
    ],
  });
});
