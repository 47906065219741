import type { IQueryParams } from '~/services/types';
import { useIMutation } from '~/services/useMutation';
import type { IFetchUser, IUser } from '~/types/common';
import { useIQuery } from '~/services/useQuery';
import { useNuxtApp } from '#app';

export const checkAccount = (options?: IQueryParams) =>
  useIMutation<{ time_to_call: number }>('v5/web/auth/send-code/', { ...options, method: 'post' });

export const submitAccount = (options?: IQueryParams) =>
  useIMutation<IFetchUser>('v5/web/auth/login/', { ...options, method: 'post' });

export const getAccountDetails = (options?: IQueryParams) =>
  useNuxtApp().$apiFetch<IUser>('v5/web/user-info/', { ...options, method: 'get' });

export const accountLogout = (options?: IQueryParams) =>
  useNuxtApp().$apiFetch<IUser>('v4/logout/', { ...options, method: 'post' });
