<script lang="ts" setup>
  const { LayerStore } = useLayerConductor();
</script>

<template>
  <teleport to="body">
    <transition-group>
      <component
        v-for="(modal, index) in LayerStore"
        :is="modal.component"
        v-bind="modal.props"
        v-on="modal.events"
        :key="index"
      />
    </transition-group>
  </teleport>
</template>

<style lang="scss" scoped></style>
