import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIcon4k, LazyIconAd, LazyIconArrowLeftBtn, LazyIconArrowLeft, LazyIconArrowRightBtn, LazyIconArrowRight, LazyIconArrowTop, LazyIconBell, LazyIconBookmark, LazyIconBrowse, LazyIconBurger, LazyIconCalendarCheck, LazyIconCaret, LazyIconCheck, LazyIconCheked, LazyIconChevronDown, LazyIconClock, LazyIconClose, LazyIconDolby, LazyIconDownload, LazyIconEdit, LazyIconEyeClose, LazyIconEyeOpen, LazyIconFeWallet, LazyIconFilter, LazyIconFlex, LazyIconFlexcoin1, LazyIconFlexcoin, LazyIconFolder, LazyIconFullHd, LazyIconHd, LazyIconHome, LazyIconImdb, LazyIconKp, LazyIconLoading, LazyIconMail, LazyIconMic, LazyIconMinus, LazyIconPlay, LazyIconPlus, LazyIconProfile, LazyIconSd, LazyIconSearch, LazyIconSmartphone, LazyIconSort, LazyIconStereo, LazyIconSuccess, LazyIconTelegram, LazyIconTrash, LazyIconTvshows, LazyIconUser, LazyIconVk, LazyIconVolume, LazyIconVoteStar, LazyIconWallet, LazyIconZen } from '#components'
const lazyGlobalComponents = [
  ["Icon4k", LazyIcon4k],
["IconAd", LazyIconAd],
["IconArrowLeftBtn", LazyIconArrowLeftBtn],
["IconArrowLeft", LazyIconArrowLeft],
["IconArrowRightBtn", LazyIconArrowRightBtn],
["IconArrowRight", LazyIconArrowRight],
["IconArrowTop", LazyIconArrowTop],
["IconBell", LazyIconBell],
["IconBookmark", LazyIconBookmark],
["IconBrowse", LazyIconBrowse],
["IconBurger", LazyIconBurger],
["IconCalendarCheck", LazyIconCalendarCheck],
["IconCaret", LazyIconCaret],
["IconCheck", LazyIconCheck],
["IconCheked", LazyIconCheked],
["IconChevronDown", LazyIconChevronDown],
["IconClock", LazyIconClock],
["IconClose", LazyIconClose],
["IconDolby", LazyIconDolby],
["IconDownload", LazyIconDownload],
["IconEdit", LazyIconEdit],
["IconEyeClose", LazyIconEyeClose],
["IconEyeOpen", LazyIconEyeOpen],
["IconFeWallet", LazyIconFeWallet],
["IconFilter", LazyIconFilter],
["IconFlex", LazyIconFlex],
["IconFlexcoin1", LazyIconFlexcoin1],
["IconFlexcoin", LazyIconFlexcoin],
["IconFolder", LazyIconFolder],
["IconFullHd", LazyIconFullHd],
["IconHd", LazyIconHd],
["IconHome", LazyIconHome],
["IconImdb", LazyIconImdb],
["IconKp", LazyIconKp],
["IconLoading", LazyIconLoading],
["IconMail", LazyIconMail],
["IconMic", LazyIconMic],
["IconMinus", LazyIconMinus],
["IconPlay", LazyIconPlay],
["IconPlus", LazyIconPlus],
["IconProfile", LazyIconProfile],
["IconSd", LazyIconSd],
["IconSearch", LazyIconSearch],
["IconSmartphone", LazyIconSmartphone],
["IconSort", LazyIconSort],
["IconStereo", LazyIconStereo],
["IconSuccess", LazyIconSuccess],
["IconTelegram", LazyIconTelegram],
["IconTrash", LazyIconTrash],
["IconTvshows", LazyIconTvshows],
["IconUser", LazyIconUser],
["IconVk", LazyIconVk],
["IconVolume", LazyIconVolume],
["IconVoteStar", LazyIconVoteStar],
["IconWallet", LazyIconWallet],
["IconZen", LazyIconZen],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
