<script lang="ts" setup>
  import { useImage } from '@vueuse/core';
  import { h, type Slot, Transition } from 'vue';

  interface IProps {
    src?: string | null;
    alt?: string | null;
    transition?: 'fade' | string;
    transitionAll?: boolean;
    loading?: 'lazy' | 'eager';
  }
  const props = withDefaults(defineProps<IProps>(), {
    src: '/',
    alt: '/',
    transition: '',
    loading: 'eager',
  });
  const slots = defineSlots<{ default?: Slot; loading?: Slot; error?: Slot }>();

  const data = reactive(useImage({ src: props.src || '' }));
  const attrs = useAttrs();

  const transition = (slot: any) =>
    h(
      Transition,
      {
        name: props.transition,
        mode: 'out-in',
        appear: !props.transitionAll,
      },
      slot,
    );

  const renderImage = () => {
    const loading = slots?.loading?.(data);
    const error = slots?.error?.(data.error);
    const defaults = slots?.default?.(data);

    return (
      (data.isLoading && loading) ||
      (data.error && error) ||
      (slots?.default && defaults) ||
      (props.transitionAll ? h('img', { ...props, ...attrs }) : transition(() => h('img', { ...props, ...attrs })))
    );
  };

  const virtualImage = computed(() => (props.transitionAll ? transition(renderImage) : renderImage));
</script>

<template>
  <virtual-image />
</template>
