<script lang="ts" setup>
  import { getTimeRemaining } from '~/helpers/datetime';
  import { pluralize } from '~/helpers/processing';
  import { storeToRefs } from '#imports';
  import VButton from './ui/VButton.vue';
  import type { RouteLocationRaw } from 'vue-router';
  import VImageProfileLoader from './VImageProfileLoader.vue';

  interface IProps {
    size?: 'extra' | 'large' | 'middle' | 'small' | 'default';
    type?: 'avatar' | 'person' | 'child';
    media?: 'extra' | 'large' | 'middle' | 'small' | 'default';
    active?: boolean;
    tag?: string;
    to?: RouteLocationRaw;
    image?: string;
    description?: boolean;
    title?: string;
    status?: string;
    useImageLoader?: boolean;
    isProfile?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    size: 'default',
    type: 'person',
  });

  const { user } = storeToRefs(useAuthStore());

  const expirationDate = computed(() => {
    if (user.value?.subscription?.status !== 'paid') return 0;
    return getTimeRemaining(user.value.subscription?.expiration_date);
  });
  const daysPlural = computed(() => pluralize(expirationDate.value, ['день', 'дня', 'дней']));
  const shortName = computed(() => user.value && user.value.first_name.charAt(0) + user.value.last_name.charAt(0));
  const component = computed(() => (props.to ? 'router-link' : props.isProfile ? 'div' : 'button'));
</script>

<template>
  <component
    :is="component"
    class="user"
    :class="[`user--media-${props.media}`, { active: props.active }]"
    :to="props.to"
    :data-size="props.size"
  >
    <span class="user__image">
      <v-image-profile-loader v-if="props.useImageLoader" />
      <template v-if="props.type === 'person'">
        <v-lazy>
          <v-image :src="props.image" :alt="props.title" transition="fade">
            <template #loading><icon-user /></template>
            <template #error><icon-user /></template>
          </v-image>
        </v-lazy>
      </template>
      <template v-else-if="props.type === 'child'">
        <v-image :src="props.image" :alt="props.title" transition="fade">
          <template #loading><icon-user /></template>
          <template #error><icon-user /></template>
        </v-image>
      </template>
      <template v-else>
        <v-image v-if="user?.avatar || shortName" :src="user?.avatar" :alt="shortName" transition="fade" transition-all>
          <template #loading>
            <span class="user__short">{{ shortName }}</span>
          </template>
          <template #error>
            <span class="user__short">{{ shortName }}</span>
          </template>
        </v-image>
        <icon-user v-else />
      </template>
    </span>
    <template v-if="props.type === 'person'">
      <span class="user__name">
        <span class="user__title">{{ props.title }} </span>
        <span class="user__status">{{ props.status }}</span>
      </span>
    </template>
    <template v-else-if="props.type === 'child'">
      <span class="user__name">
        <span class="user__title">Детский профиль</span>
      </span>
    </template>
    <template v-else>
      <span v-if="props.description" class="user__name" :class="{ 'user__name--edit': props.isProfile }">
        <span class="user__title">
          {{ user?.phone || user?.email }}
        </span>
        <v-button
          class="user__edit-button"
          icon="edit"
          size="small"
          appearance="text"
          @click="$emit('edit-profile')"
          v-if="props.isProfile"
        />
        <span v-if="expirationDate" class="user__status highlight">{{ expirationDate }} {{ daysPlural }}</span>
        <span v-else class="user__status highlight--red">Нет подписки</span>
      </span>
      <span v-else class="user__caret">
        <icon-caret />
      </span>
    </template>
  </component>
</template>

<style lang="scss" scoped>
  $size-properties: (
    'extra': (
      'user-header-size': 20px,
      'user-header-height': 24px,
      'user-gap': 16px,
      'user-name-gap': 0px,
      'user-image-size': 80px,
      'user-border-radius': 24px,
      'user-svg-size': 28px,
    ),
    'large': (
      'user-header-size': 18px,
      'user-gap': 16px,
      'user-image-size': 60px,
      'user-border-radius': 12px,
      'user-short-size': 18px,
      'user-svg-size': 24px,
    ),
    'middle': (
      'user-header-size': 14px,
      'user-header-height': 20px,
      'user-status-size': 12px,
      'user-status-height': 16px,
      'user-gap': 16px,
      'user-image-size': 48px,
      'user-border-radius': 10px,
      'user-short-size': 16px,
      'user-svg-size': 24px,
    ),
    'small': (
      'user-gap': 16px,
      'user-image-size': 24px,
      'user-border-radius': 6px,
      'user-short-size': 10px,
      'user-header-size': 14px,
      'user-header-weight': 400,
      'user-svg-size': 12px,
    ),
    'default': (
      'user-padding': 6px 8px,
    ),
  );

  .user {
    $parent: &;
    --user-image-size: 36px;
    --user-image-color: #{$main_white};
    --user-hover-color: #{$main_grey_dark};
    --user-background: #{$avatar_bg_secondary};
    --user-gap: 8px;
    --user-short-size: 12px;
    --user-border-radius: 8px;
    --user-header-size: 16px;
    --user-header-height: 20px;
    --user-status-size: 16px;
    --user-status-height: 24px;
    --user-header-weight: 600;
    --user-name-gap: 8px;
    --user-padding: 0px;
    --user-svg-size: 16px;

    display: flex;
    align-items: center;
    gap: var(--user-gap);
    cursor: pointer;
    padding: var(--user-padding);
    border-radius: 8px;
    height: fit-content;

    svg {
      width: var(--user-svg-size);
      height: var(--user-svg-size);
    }

    &__image {
      position: relative;
      width: var(--user-image-size);
      min-width: var(--user-image-size);
      height: var(--user-image-size);
      background-color: var(--user-background);
      border-radius: var(--user-border-radius);
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--user-image-color);
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__short {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--user-image-color);
      font-size: var(--user-short-size);
      font-style: normal;
      font-weight: 300;
      line-height: 28px;
    }
    &__name {
      display: grid;
      gap: var(--user-name-gap);
      &--edit {
        grid-template-columns: 1fr auto;
      }
    }
    &__title {
      font-size: var(--user-header-size);
      font-style: normal;
      font-weight: var(--user-header-weight);
      line-height: var(--user-header-height);
      color: var(--user-image-color);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &__status {
      color: $main_white;
      font-size: var(--user-status-size);
      font-style: normal;
      font-weight: 400;
      line-height: var(--user-status-height);
      opacity: 0.6;
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      &.highlight {
        color: $color-primary;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 6px;
        background-color: $main_green_light;
        opacity: 1;
      }
      &.highlight--red {
        color: $main_white;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        padding: 0 8px;
        border-radius: 6px;
        background-color: $main_red_light;
        opacity: 1;
      }
    }
    &__caret {
      color: var(--user-image-color);
      transition: 0.3s;
      #{$parent}:hover & {
        color: var(--user-hover-color);
      }
    }
    &__edit-button {
      padding: 0;
    }
    &.active {
      background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%), #0b0b0b;
      #{$parent} {
        &__caret {
          transform: rotate(180deg);
        }
      }
    }
    @each $size, $properties in $size-properties {
      &[data-size='#{$size}'] {
        @each $property, $value in $properties {
          --#{$property}: #{$value};
        }

        @if $size == 'small' {
          #{$parent}__caret {
            display: none;
          }
        }
      }
    }

    @media (max-width: $retina) {
      @each $media-size, $media-properties in $size-properties {
        &.user--media-#{$media-size} {
          @each $property, $value in $media-properties {
            --#{$property}: #{$value};
          }

          @if $media-size == 'small' {
            #{$parent}__caret {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
