import { useMediaQuery } from '@vueuse/core';

export const useHeader = () => {
  const { afterEach } = useRouter();
  const mobileHeader = useState('mobile-header');
  const isMobile = useMediaQuery('(max-width: 640px)');

  const setHeader = (value: string) => {
    if (isMobile.value) {
      mobileHeader.value = value;
    }
  };
  const resetHeader = () => {
    mobileHeader.value = '';
  };
  afterEach(() => resetHeader());
  return {
    mobileHeader,
    setHeader,
    resetHeader,
  };
};
