import { parseValue } from '~/helpers/common';

export const parseSearchParams = (url: string) => {
  const queryString = new URLSearchParams(url.substring(url.indexOf('?')));
  return [...queryString].reduce(
    (acc, [key, value]) => {
      acc[key] = parseValue(value);
      return acc;
    },
    {} as { [key: string]: string | number | boolean | undefined },
  );
};

export const clearQueries = (queryNames: string[]) => {
  const nuxtRoute = useRoute();
  queryNames.forEach(queryName => {
    delete nuxtRoute.query[queryName];
  });
};

export const objectToQueryString = (params: { [key: string]: any }) => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => value || value === 0)
    .map(([key, value]) => `${encodeURIComponent(key)}=${value}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};

export const hasTargetDomains = (domain: string, targetDomains: string[]) => {
  const domainParts = domain.split('.');
  const relevantParts = domainParts.slice(-2);

  return targetDomains.some(target => relevantParts.some(part => new RegExp(target, 'i').test(part)));
};
