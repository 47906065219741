<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';

  const FiltersModal = defineAsyncComponent(() => import('~/components/modals/filter/Index.vue'));
  const { onReveal } = createModal(FiltersModal);

  const targetPath = ['browse-category', 'browse-genre-slug'];
  const route = useRoute();

  const showButton = computed(() => targetPath.includes(String(route.name)));
</script>

<template>
  <v-button
    v-if="showButton"
    class="browse-filter"
    appearance="text"
    color="white"
    size="normal"
    icon="filter"
    rounded
    @click="onReveal()"
  />
</template>

<style lang="scss" scoped>
  .browse-filter {
    display: none;
    &.v-button[data-size='normal'] {
      padding: 2px 0;
    }
    @media (max-width: $retina) {
      display: block;
    }
  }
</style>
