import { default as indexpuUYRudbbsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexydP7Ko4AmrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersKKnN7lsmMnMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionGenresfaJI5uAZvEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesCjlw5rw8oxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularVcJ79HKuc9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSeries3bR37f1XyvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFilters6S0S3Vz8lYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenresbXzvk8emphMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93zrHl3tsUhNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexGEBkmC0f3sMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93gC4rHziDKiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/collections/[slug].vue?macro=true";
import { default as indexFQnWP6ZpkhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/collections/index.vue?macro=true";
import { default as copyrightuettKZSQsHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/copyright.vue?macro=true";
import { default as _91id_9349D9M7VhzlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/crew/[id].vue?macro=true";
import { default as index7RpuA3Q1AvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/faq/index.vue?macro=true";
import { default as allYasUXtxXQQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/all.vue?macro=true";
import { default as SectionBought8AFPBqXRI9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinueyp5yqABuEeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoriteswKUMxieh6pMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewedq5Lnl0jgqMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continueWjHpEXkEueMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/continue.vue?macro=true";
import { default as indexpsbZP7dDebMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/index.vue?macro=true";
import { default as purchasedgU7S5bDD5NMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedQpLci5GfpbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policy8A1ZveR8mgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesDudWahSY68Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionCollectionsXeiV5npuMNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinueONhaMWwq8YMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilmsjN6FI4sYHLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenrese0D4m0wC5IMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeaderlMWtwtvnEMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopularQV35eJq2BZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersVUeqiGZwjtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeriesTg1dpqqGPXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptions5qgI5WdvUIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizeRTRJo9THEvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionUpcoming9xJSTobtn4Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndextOtdsphAiTMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93k6gPDvguYdMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstall240GMAZG5aMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstallxU9PreGXZDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesCmMFp0rjrkMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/types.ts?macro=true";
import { default as useInstallMLrPXwwizDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/useInstall.ts?macro=true";
import { default as _91slug_93iIqR33JChuMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/[slug].vue?macro=true";
import { default as MovieCollectionsLcaJ3gYI0sMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewm6rllFBPmWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionLeaCiZ6WP1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderPF97pZ6dnHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeOySk8uWSXzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonOqkEfJAtONMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButton98SfjvuQsjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelated61s9xJsAhQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesm99w1JsMMIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersAwPxSbWqkrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsmZGPYamv9ZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93V1D4sVgJw8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/news/[slug].vue?macro=true";
import { default as index6rJdkUIUVxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmstJrBmUMV7jMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45series1SBJqGn8fUMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementqCLQXdds2jMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyrightgE6eYTpHK1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holdereLtqvoz6PmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privateRX28TsAu1TMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-private.vue?macro=true";
import { default as policy_45userTWII1raCwCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-user.vue?macro=true";
import { default as premiersGSGjpRlfkmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/premiers.vue?macro=true";
import { default as private_45policyeXVacexrQhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/private-policy.vue?macro=true";
import { default as indexTzUidajAoLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalw7pz4E6liyMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfileSoILeKH3jKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModal4vhQ3JShNrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VResumeSubscriptionSuccessModalDQF3QzcwjLMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferreruFeZAo5FEwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationxjPOX1MzbRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryUvqwCiqgVhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardm2hQR7qBMoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsbTC9cVqjJlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPayments0LVTvDe5zoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabsozDAzvATe7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramGuP8sX0hYZMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionswvCSMbGyzCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexVpbx7alGnCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/devices/index.vue?macro=true";
import { default as indexguDVYbnugHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/index.vue?macro=true";
import { default as index56ycgTZGGIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/notifications/index.vue?macro=true";
import { default as index93lVuGhmRqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/payments/index.vue?macro=true";
import { default as index7E14VwNzEnMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/settings/index.vue?macro=true";
import { default as resultJf6y3ALW0cMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/result.vue?macro=true";
import { default as _91slug_93eLzj1wjY16Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/similar/[slug].vue?macro=true";
import { default as indexJ4w0NoYBu7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/sports/channels/index.vue?macro=true";
import { default as indexfDlcomzSDMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsBzGxhc4bVMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContentfSlZu3awRhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDeviceshhYFKbkntzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadGbANoDWbQHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeaderi3Vc0rHO2uMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsGzWK8fXx7zMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceovern3UxrWZi9eMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as index3EsWOdk8gtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/index.vue?macro=true";
import { default as termsE8Wi6NAbSxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/terms.vue?macro=true";
import { default as upcomingCQSS8djdmiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/[slug].vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexTzUidajAoLMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexVpbx7alGnCMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexguDVYbnugHMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: index56ycgTZGGIMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: index93lVuGhmRqMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: index7E14VwNzEnMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexfDlcomzSDMMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-277866/src/pages/upcoming.vue").then(m => m.default || m)
  }
]