import * as Sentry from '@sentry/nuxt';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  Sentry.init({
    dsn: config.public.sentry.dsn,
    integrations: [
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
    ],
    beforeBreadcrumb(breadcrumb) {
      const isMetricMouseMove = breadcrumb?.category === 'fetch' && breadcrumb?.data?.url?.includes('mc.yandex.ru/webvisor');
      if (isMetricMouseMove) {
        return null;
      }

      return breadcrumb;
    },
    //Вероятность отправки данных наблюдения в Sentry
    tracesSampleRate: 1,
    //Общая вероятность записи сессии пользователя(независимо от наличия ошибки)
    replaysSessionSampleRate: 0,
    //Вероятность записи сессии в случаях, когда произошла ошибка
    replaysOnErrorSampleRate: 1,
  });
});
