import type { IQueryParams } from '~/services/types';
import { useIQuery } from '~/services/useQuery';
import type { IHTMLPage, IStores } from '~/types/common';

export const getTermsUser = (options?: IQueryParams) =>
  useIQuery<IHTMLPage>('v4/terms/user/', { ...options, pagination: false });

export const getTermsPolicy = (options?: IQueryParams) =>
  useIQuery<IHTMLPage>('v4/terms/holder-policy/', { ...options, pagination: false });

export const getTermsPrivatePolicy = (options?: IQueryParams) =>
  useIQuery<IHTMLPage>('v4/terms/private-policy/', { ...options, pagination: false });

export const getTermsAgreementPolicy = (options?: IQueryParams) =>
  useIQuery<IHTMLPage>('v4/terms/agreement-18/', { ...options, pagination: false });

export const getStoresInfo = (options?: IQueryParams) =>
  useIQuery<IStores>('v4/site-info/', { ...options, pagination: false });
