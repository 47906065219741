<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';
  import VProfileMenuLinks from '~/components/header/VProfileMenuLinks.vue';
  import VProfileMenuBalance from '~/components/header/VProfileMenuBalance.vue';

  const VEditProfileModal = defineAsyncComponent(() => import('~/pages/profile/components/modal/VEditProfile.vue'));
  const { onReveal } = createModal(VEditProfileModal);
  const router = useRouter();
  interface IProps {
    isProfile?: boolean;
    useImageLoader?: boolean;
  }
  const props = defineProps<IProps>();

  const { logout } = useAuthStore();
  const { $updateQueries } = useAuth();

  const logoutAction = async () => {
    await logout();
    await $updateQueries();
  };
  const handleUserClick = () => {
    if (!props.isProfile) return router.push({ name: 'profile' });
  };
</script>

<template>
  <div class="profile-menu">
    <v-user
      type="avatar"
      size="middle"
      description
      :use-image-loader="props.useImageLoader"
      :is-profile="props.isProfile"
      @click="handleUserClick()"
      @edit-profile="onReveal()"
    />
    <!-- <v-user type="child" description size="small" /> -->
    <v-profile-menu-balance />
    <v-profile-menu-links :is-profile="props.isProfile" />
    <v-button class="profile-menu__button" appearance="text" color="secondary" @click="logoutAction">Выйти</v-button>
  </div>
</template>

<style lang="scss" scoped>
  .profile-menu {
    border-radius: 16px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%), #0b0b0b;
    padding: 24px;
    width: 300px;
    z-index: 100;
    display: grid;
    gap: 24px;
    &__button {
      width: fit-content;
      padding: 10px 0;
      color: $main_red_light;
    }
  }
</style>
