import useStatefulCookie from '~/composables/useStatefulCookies';
import type { AdvertiseParams, CityAdsParams } from '~/types/common';
import { clearQueries } from '~/helpers/url';

export default defineNuxtPlugin(async (nuxtApp: any) => {
  enum EnumSource {
    city = 'cityads',
    advertise = 'advertise',
  }

  const { searchParams } = useRequestURL();
  const referrer = useStatefulCookie('referrer', {}, 30);
  const promo_code = useStatefulCookie('promo_code', {}, 1);
  const cityAds = useStatefulCookie<CityAdsParams>('cityAds', {}, 30);
  const advertise = useStatefulCookie<AdvertiseParams>('advertise', {}, 30);
  const params: any = Object.fromEntries(searchParams);
  const queryKeys = Object.keys(params);

  if (params.utm_source === EnumSource.city && !advertise.value) cityAds.value = params;
  if (params.utm_source === EnumSource.advertise && !cityAds.value) advertise.value = params;
  if (params.referer) referrer.value = params.referer;
  if (params.promo_code) promo_code.value = params.promo_code;
});
