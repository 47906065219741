import {
  useQuery,
  useInfiniteQuery,
  type UseInfiniteQueryReturnType,
  type UseQueryReturnType,
} from '@tanstack/vue-query';
import { useNuxtApp } from '#app';
import type { IResponse } from '~/types/common';
import flatMap from 'lodash-es/flatMap';
import type { IQueryParams } from '~/services/types';
import { isNullable } from '~/helpers/common';

interface IQueryOptions<D extends boolean> extends IQueryParams {
  pagination?: D;
  torrent?: D;
}

export function useIQuery<T>(url: string, options?: IQueryOptions<true>): UseInfiniteQueryReturnType<T, any>;
export function useIQuery<T>(url: string, options?: IQueryOptions<false>): UseQueryReturnType<T, any>;
export function useIQuery<T>(url: string, options?: IQueryOptions<boolean>) {
  let queryKey: string[] = [url, options?.params];
  if (options?.key) {
    queryKey = Array.isArray(options.key) ? options.key : [options.key];
  }

  if (options?.pagination) {
    return useInfiniteQuery({
      queryKey,
      queryFn: ({ pageParam, queryKey }) => {
        return useNuxtApp().$apiFetch<IResponse<T>>(url, {
          params: { [options.torrent ? 'torrent_page' : 'page']: pageParam, ...unref(options.params) },
          method: options?.method,
        });
        // TODO: trick for cancel refetch on mount if query with error on server but there is problem with reset cache
        // try {
        //   return useNuxtApp().$apiFetch<IResponse<T>>(url, {
        //     params: { page: pageParam, ...unref(options.params) },
        //     method: options?.method,
        //   });
        // } catch (e) {
        //   return Promise.resolve<IResponse<T>>({ results: [], count: 0, next: null, previous: null });
        // }
      },
      getNextPageParam: (lastPage, _, lastPageParam) => {
        if (!lastPage.next) return undefined;
        return lastPageParam + 1;
      },
      select: ({ pages = [] }) => flatMap(pages, ({ results }) => results),
      initialPageParam: 1,
      ...options?.options,
    });
  }
  return useQuery({
    queryKey,
    queryFn: () => useNuxtApp().$apiFetch<T>(url, { params: unref(options?.params), method: options?.method }),
    ...options?.options,
  });
}
