export function pluralize(number: number, forms: string[]): string {
  const cases: number[] = [2, 0, 1, 1, 1, 2];
  return forms[number % 100 > 4 && number % 100 < 20 ? 2 : cases[Math.min(number % 10, 5)]];
}

export function cleanPhoneNumber(phone: string) {
  return phone
    .replace(/^(?:\+7|8|7)?[\s\-]?\(?|\)?[\s\-]?/g, '')
    .replace(/[^\d]/g, '')
    .slice(-10);
}

export function formatPhoneNumber(phone: string, mask: string): string {
  const cleaned = cleanPhoneNumber(phone); // Убираем все, кроме цифр
  let formattedPhone = mask;

  for (let i = 0; i < cleaned.length; i++) {
    formattedPhone = formattedPhone.replace('#', cleaned[i]);
  }

  return formattedPhone;
}

export const removeSpaces = (str: string): string => str.replace(/\s+/g, '');
