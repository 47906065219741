<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';
  import type { IFooterItem } from '~/components/footer/types';

  interface IProps {
    item: IFooterItem;
    hidden?: boolean;
  }

  const props = defineProps<IProps>();
</script>

<template>
  <div class="footer-menu">
    <div class="footer-menu__header" v-if="props.item.header">{{ props.item.header }}</div>
    <ul class="footer-menu__list" :class="{ row: props.item.type === 'row' }">
      <li v-for="(link, index) in props.item.list" :key="index" class="footer-menu__item">
        <v-button
          v-if="link.icon && link.route"
          size="small"
          color="gray"
          rounded
          :icon="link.icon"
          custom-tag="a"
          :href="link.route"
          :aria-hidden="props.hidden"
          :aria-label="link.icon"
        />
        <nuxt-link v-if="link.name" :to="link.route">{{ link.name }}</nuxt-link>
      </li>
    </ul>
    <v-button
      v-if="props.item.button"
      size="small"
      media="normal"
      color="gray"
      class="footer-menu__button"
      :to="props.item.button.route"
    >
      {{ props.item.button.name }}
    </v-button>
  </div>
</template>

<style lang="scss" scoped>
  .footer-menu {
    display: grid;
    gap: 24px;
    align-content: flex-start;
    min-width: 136px;

    @media (min-width: $tablet-small) and (max-width: $tablet) {
      grid-row: span 2;
    }

    &:nth-child(3) {
      @media (min-width: $tablet-small) and (max-width: $tablet) {
        grid-row: span 1;
      }
    }

    &:last-child {
      flex: 1;
      justify-content: flex-end;

      @media (min-width: $tablet-small) and (max-width: $tablet) {
        justify-content: flex-start;
        grid-row: span 1;
        grid-column: 3;
      }

      @media (max-width: $tablet-small) {
        align-self: flex-end;
        justify-content: flex-end !important;
      }

      @media (max-width: 540px) {
        display: none;
      }
    }

    &__header {
      color: $main_white;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
    }

    &__list {
      display: grid;
      gap: 8px;
      height: fit-content;

      &.row {
        display: flex;
      }
    }

    &__item {
      color: $main_grey_mid;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      height: fit-content;
    }

    &__button {
      height: fit-content;
    }

    &:first-child & {
      &__item {
        font-size: 18px;
        line-height: 26px;
      }
    }

    @media (max-width: $mobile-large) {
      &:first-child & {
        &__list {
          grid-template-columns: 1fr 1fr;
          gap: 16px;
        }

        &__item {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    @media (max-width: $retina) {
      &:last-child {
        justify-content: flex-start;
      }
    }
  }
</style>
