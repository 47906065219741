import type { CookieRef } from '#app';
import useStatefulCookie from '~/composables/useStatefulCookies';
import type { ICookieCredits } from '~/types/common';

export default defineNuxtPlugin((nuxt: any) => {
  const authStore = useAuthStore(nuxt.$pinia);
  const config = useRuntimeConfig();
  const credits: CookieRef<ICookieCredits | null> = useStatefulCookie('credits');
  const { checkUrlCredits, redirectToLanding } = useRedirect(nuxt);
  const { origin, href } = useRequestURL();

  const tokens = checkUrlCredits();
  if (tokens.access) credits.value = tokens;

  const $apiFetch = $fetch.create({
    baseURL: config.public.baseURL,
    headers: credits.value?.access ? { Authorization: `JWT ${credits.value?.access}` } : {},
    retryStatusCodes: [401, 500, 502],
    retry: false,
    onRequest({ options }) {
      options.headers = {
        ...options.headers,
        Authorization: credits.value?.access ? `JWT ${credits.value?.access}` : '',
        referer: href,
      };
    },
    onResponse({ response, options }) {
      if (response.status === 200) {
        if (response._data?.torrent) {
          response._data = {
            ...response._data,
            results: response._data?.torrent,
            next: response._data?.torrent_next,
          };
        }
        if (response._data instanceof Array) {
          response._data = {
            count: response._data.length,
            results: response._data,
          };
        }
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401 && response.url !== 'tokenAuthUrl' && response.url !== 'tokensRefreshUrl') {
        credits.value = null;
        authStore.$resetState();
        redirectToLanding();
      }
    },
  });
  return {
    provide: {
      apiFetch: $apiFetch,
    },
  };
});
