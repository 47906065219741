<script lang="ts" setup>
  import VButton from '~/components/ui/VButton.vue';

  const BalanceModal = defineAsyncComponent(() => import('~/components/modals/balance/Index.vue'));
  const { onReveal } = createModal(BalanceModal);
  const { user } = storeToRefs(useAuthStore());
  const balance = computed(() => (user.value?.balance ?? 0) / 100);
</script>

<template>
  <div class="profile-balance">
    <div class="profile-balance__header">
      <span>Баланс</span>
      <sub>{{ balance }} {{ user?.currency.sign }}</sub>
    </div>
    <v-button color="white" size="small" icon="plus" @click="onReveal()">Пополнить баланс</v-button>
  </div>
</template>

<style lang="scss" scoped>
  .profile-balance {
    display: grid;
    gap: 20px;
    padding: 16px;
    background-color: $main_black;
    border-radius: 14px;
    &__header {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: $main_white;
      }
      sub {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #ddff7c;
      }
    }
  }
</style>
