<script setup lang="ts">
  const getIcon = (name: string) => defineAsyncComponent(() => import(`./icons/${name}.svg?skipsvgo`));

  export interface ISocialItem {
    to: string;
    icon: string;
    title: string;
  }

  defineProps<{ item: ISocialItem }>();
</script>

<template>
  <NuxtLink class="social-items" :to="item.to">
    <component :is="getIcon(item.icon)" class="social-items__icon" />
    {{ item.title }}
  </NuxtLink>
</template>

<style scoped lang="scss">
  .social-items{
    border: 0.7px solid $Main_white_light;
    padding: 8px 16px 8px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 13px;
    color: $main_white;
    font-family: 'Montserrat', sans-serif;

    &__icon {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 13px;
      color: $main_white;
      width: 18px;
      height: 18px;
    }
  }
</style>