<script setup lang="ts">
  import { updateUserProfile } from '~/services/modules/profile.service';
  import { useAuthStore } from '~/stores/auth';

  const { fetchUser } = useAuthStore();

  type FileChangeEvent = Event & {
    target: HTMLInputElement;
  };

  const isHover = ref(false);
  const isLoading = ref(false);
  const file = ref<File | null>(null);
  const fileInput = ref<HTMLElement | null>(null);

  const uploadFile = async (event: FileChangeEvent) => {
    file.value = event?.target?.files?.[0] ?? null;
    if (!file.value || isLoading.value) return;
    try {
      isLoading.value = true;
      const formData = new FormData();
      formData.append('avatar', file.value);
      await updateUserProfile(formData);
      await fetchUser();
    } catch (error) {
      console.error('Error send avatar: ', error);
    } finally {
      isLoading.value = false;
      file.value = null;
    }
  };
</script>

<template>
  <div
    class="image__loader"
    @mouseenter="
      () => {
        isHover = true;
      }
    "
    @mouseleave="
      () => {
        isHover = false;
      }
    "
    @click="!isLoading ? fileInput?.click() : ''"
    @change="uploadFile"
  >
    <input type="file" name="avatar" id="avatar" accept="image/png, image/jpeg, image/gif" ref="fileInput">
    <div class="overflow" v-show="isHover || isLoading">
      <icon-download class="overflow-icon" v-show="!isLoading" />
      <icon-loading class="overflow-icon" v-show="isLoading" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .image__loader {
    position: absolute;
    width: 100%;
    height: 100%;

    input {
      display: none;
    }

    .overflow {
      position: absolute;
      width: inherit;
      height: inherit;
      background-color: rgba($color: $main_grey_extra_dark, $alpha: 0.7);
      z-index: 100;
      display: flex;
      justify-content: center;
      align-items: center;

      &-icon {
        width: 24px;
        height: 24px;
        fill: $color-primary;
      }
    }
  }
</style>
