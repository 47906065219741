export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  setTimeout(() => {
    useHead({
      script: [
        {
          src: '//code.jivo.ru/widget/' + config.public.jivoId,
          async: true,
          tagPosition: 'bodyClose',
        },
      ],
    });
  }, 5000);
});
