<script setup lang="ts">
  import { useIntersectionObserver } from '@vueuse/core';

  interface IProps {
    renderOnIdle?: boolean;
    unRender?: boolean;
    minHeight?: number;
    unRenderDelay?: number;
    section?: boolean;
  }
  const props = withDefaults(defineProps<IProps>(), {
    unRenderDelay: 10000,
    minHeight: 0,
  });

  function onIdle(cb = () => {}) {
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(cb);
    } else {
      setTimeout(() => {
        nextTick(cb);
      }, 300);
    }
  }

  const shouldRender = ref(false);
  const targetEl = ref();
  const fixedMinHeight = ref(0);
  let unRenderTimer: number;
  let renderTimer: number;

  const { stop } = useIntersectionObserver(
    targetEl,
    intersectionObserverEntries => {
      let isIntersecting = shouldRender.value;

      // Get the latest value of isIntersecting based on the entry time
      let latestTime = 0;
      for (const entry of intersectionObserverEntries) {
        if (entry.time >= latestTime) {
          latestTime = entry.time;
          isIntersecting = entry.isIntersecting;
        }
      }
      if (isIntersecting) {
        shouldRender.value = isIntersecting;
        stop();
      }
    },
    {
      rootMargin: '600px',
    },
  );

  if (props.renderOnIdle) {
    onIdle(() => {
      shouldRender.value = true;
      if (!props.unRender) {
        stop();
      }
    });
  }
</script>

<template>
  <component
    :is="props.section ? 'section' : 'span'"
    ref="targetEl"
    :style="`${props.minHeight ? `min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px` : ''}`"
  >
    <slot v-if="shouldRender" />
  </component>
</template>
