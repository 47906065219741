import type { RouteRecordRaw } from 'vue-router';
import Yup from 'yup';

export function objectToKeyApiString(url: string, obj: Record<string, any> = {}): string {
  return (
    url +
    Object.entries(obj)
      .map(([key, value]) => `${key}-${String(value)}`)
      .join('-')
  );
}

export function filterRoutesByExcludes(routes: readonly RouteRecordRaw[], excludes: string[]): RouteRecordRaw[] {
  return routes.filter(
    route =>
      !excludes.some(el => {
        if (typeof route.name !== 'string') return true;
        return route.name?.includes(el);
      }),
  );
}

export const parseValue = (value: string | null): string | number | boolean => {
  if (!value) return false;
  try {
    return JSON.parse(value);
  } catch (_) {
    const num = Number(value);
    return isNaN(num) ? value : num;
  }
};

export function getSubmitFn<Schema extends Yup.ObjectSchema<Record<string, any>>>(
  _: Schema,
  callback: (values: Yup.InferType<Schema>) => void,
) {
  return (values: Record<string, any>) => {
    return callback(values);
  };
}

export const errorHandler = ({ message }: { message: any }, cb: Function) => {
  if (!(message.response && '_data' in message.response)) return;
  if (message.response.status === 500) return;
  Object.entries(message.response._data).forEach(([key, err]) => {
    if (Array.isArray(err)) cb(err[0], key);
    else cb(err, key);
  });
};

export const isNullable = (value: any): boolean => {
  return (
    !value ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};
